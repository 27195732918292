import * as Noty from 'noty';
import defaultTo from 'lodash-es/defaultTo';
import map from 'lodash-es/map';
import { DataService } from '../../shared/shared-data.service';
import * as i0 from "@angular/core";
import * as i1 from "../../shared/shared-data.service";
export class NotifyService {
    constructor(dataService) {
        this.dataService = dataService;
        this.defaults = {
            theme: 'relax',
            layout: 'topCenter',
            type: 'information',
            closeWith: [],
            callbacks: {
                onShow: () => {
                    this.dataService.sendData({ type: 'notyShowing', data: true });
                },
                onClose: () => {
                    this.dataService.sendData({ type: 'notyShowing', data: false });
                },
            },
            animation: {
                open: 'animated fadeInDown',
                close: 'animated fadeOutUp'
            }
        };
    }
    warn(text, options) {
        const opts = Object.assign({}, this.defaults, {
            type: 'warning',
            text: text
        }, options);
        const noty = new Noty(opts);
        noty.show();
        return noty;
    }
    warnWithAvailableUpdates(options) {
        let noty;
        const buttons = [{
                text: 'RELOAD',
                classes: 'btn apply-update',
                callback: () => {
                    this.dataService.sendData({ type: 'updatesApplied', data: true });
                    window.location.reload();
                }
            },
            {
                text: 'DISMISS',
                classes: 'btn float-right dismiss-update',
                callback: () => {
                    this.dataService.sendData({ type: 'updatesApplied', data: false });
                    noty.close();
                }
            }];
        const opts = Object.assign({}, this.defaults, {
            type: 'warning',
            buttons: map(buttons, button => Noty.button(button.text, button.classes, defaultTo(button.callback, () => {
            }))).value()
        }, options);
        noty = new Noty(opts);
        noty.show();
        return noty;
    }
    error(text, options) {
        const opts = Object.assign({}, this.defaults, {
            type: 'error',
            text: text,
        }, options);
        const noty = new Noty(opts);
        noty.show();
        return noty;
    }
}
NotifyService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotifyService_Factory() { return new NotifyService(i0.ɵɵinject(i1.DataService)); }, token: NotifyService, providedIn: "root" });
