import { Type } from '@angular/core';
import { Subject } from 'rxjs';
import { filter } from "rxjs/operators";
import * as i0 from "@angular/core";
export class ApplicationEventBroadcaster extends Subject {
    onType(messageClass) {
        return this.asObservable().pipe(filter(event => event instanceof messageClass));
    }
}
ApplicationEventBroadcaster.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApplicationEventBroadcaster_Factory() { return new ApplicationEventBroadcaster(); }, token: ApplicationEventBroadcaster, providedIn: "root" });
