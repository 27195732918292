import * as i0 from "@angular/core";
export class ConstantService {
    constructor() {
        this._constants = {};
    }
    getConstant(key) {
        return this._constants[key];
    }
    setConstant(key, value) {
        this._constants[key] = value;
    }
}
ConstantService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ConstantService_Factory() { return new ConstantService(); }, token: ConstantService, providedIn: "root" });
